<template>
    <section class="project-view container">
        <div class="pin-section" id="pinSection"></div>
        <div class="project-list">
            <Apartment v-for="item in projects"
                       :key="item.id"
                       :item="item"
                       @mouseover="selectProject(item.id)"/>
        </div>
        <div data-scroll data-scroll-sticky
             data-scroll-target="#pinSection" class="project-active-item-content">
            <div v-for="item in projects"
                 :key="item.id"
                 :class="{active:item.id===activeProjectId}"
                 class="active-item">
                <div class="image-container">
                    <div v-html="item.render_svg" class="image"></div>
                    <div class="image-pins">
                        <div v-for="item in item.pins" class="item">
                            <div class="dot">
                                <div class="point"></div>
                            </div>
                            <div class="tooltip">
                                <span class="text fira">{{ item.tooltip.en }}</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="content">
                    <h2 class="title luxury">{{ item.description_title }}</h2>
                    <div v-html="item.description_text" class="about-list">
                    </div>
                    <div class="all-apartment">
                        <div @click="changeRoute(item.link2.url)" class="explore-container">
                            <svg width="38" height="10" class="icon" viewBox="0 0 38 10" fill="none"
                                 xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd" clip-rule="evenodd"
                                      d="M31.8652 8.75479C31.5964 9.01868 31.5746 9.47032 31.8166
                           9.76354C32.0585 10.0568 32.4726 10.0805 32.7415 9.81663L37.6484 5L32.7415
                            0.183367C32.4726 -0.0805321 32.0585 -0.0567618 31.8166 0.236459C31.5746
                             0.529679 31.5964 0.981315 31.8652 1.24521L35.0279 4.34961H0.65C0.291015
                              4.34961 0 4.64062 0 4.99961C0 5.35859 0.291015 5.64961
                              0.65 5.64961H35.0287L31.8652 8.75479Z"
                                      fill="#940128"></path>
                            </svg>
                            <span class="title fira">{{ $fn.tr('All Apartments') }}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script setup>
import Apartment from './parts/Apartment'
import {nextTick, onMounted, ref} from "vue";
import {computed} from "vue";
import {useMenuStore} from "../../../store/pinia/menu";
import {useRouter} from "vue-router";
import {provide} from "vue";

const props = defineProps({
    contentData: {
        type: Object,
    },
});
let store = useMenuStore()
let setPinSectionHeight = () => {
}
provide('project', {
    setPinSectionHeight
})
let projects = computed(() => props.contentData.data?.list?.map((item, index) => ({
    ...item,
    mobile: item?.images?.[0]?.devices?.mobile,
    desktop: item?.images?.[0]?.devices?.desktop,
    tablet: item?.images?.[0]?.devices?.tablet,
    mobile1: item?.images2?.[0]?.devices?.mobile,
    desktop1: item?.images2?.[0]?.devices?.desktop,
    tablet1: item?.images2?.[0]?.devices?.tablet,
    views: [
        {
            title: item.view?.view_1_text,
            icon: item.view?.view_1_icon
        },
        {
            title: item.view?.view_2_text,
            icon: item.view?.view_2_icon
        },
    ],
    pins: item?.pins?.tabs?.[0]?.selected_pins,
    render_svg: item?.pins?.tabs?.[0]?.render_svg,
})))
let activeProjectId = ref(projects.value?.[0]?.id)
let router = useRouter()

const changeRoute = (link) => {
    router.push(`/${link}`)

}
const setPins = async () => {
    await nextTick()

    let imagePosition = document.querySelector('.image-container')?.getBoundingClientRect()
    let activePins = projects.value?.find(item => item.id === activeProjectId.value)?.pins
    let pins = document.querySelectorAll('.image-pins .item')

    activePins?.forEach((item, index) => {
        pins[index].style.left = `${item.x}%`
        pins[index].style.top = `${item.y}%`
    })
}
const selectProject = (itemId) => {
    activeProjectId.value = itemId
}

onMounted(() => {
    setPins()
})

</script>

<style lang="scss" scoped>
.project-view {
    $ease: cubic-bezier(.09, .14, .2, 1);
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    position: relative;
    margin-bottom: 430px;
    margin-top: 233px;
    @media (max-width: 1023px) {
        flex-direction: column-reverse;
        margin-bottom: 0;
        margin-top: 89px;
    }

    .pin-section {
        position: absolute;
        height: calc(100% + 410px);
        top: 0;
        pointer-events: none;
        margin-top: -180px;
        @media (max-width: 1919px) {
            margin-top: -114px;
        }
        @media (max-width: 1023px) {
            display: none;
        }
    }

    .project-list {
        width: calc(50% - 10px);
        @media (max-width: 1023px) {
            width: 100%;
        }
    }

    .project-active-item-content {
        width: calc(50% - 10px);
        position: relative;
        height: 750px;
        @media (max-width: 1023px) {
            width: 100%;
            height: unset;
        }

        .active-item :deep {
            width: 100%;
            pointer-events: none;
            top: 0;
            position: absolute;
            transition: all .45s $ease;
            @media (max-width: 1023px) {
                position: relative;
                display: none !important;
                opacity: 0;
                &:first-child {
                    display: block !important;
                    opacity: 1 !important;
                    pointer-events: auto !important;

                    .image {
                        img {
                            opacity: 1 !important;
                        }
                    }

                    .title {
                        opacity: 1 !important;
                    }

                    li {
                        opacity: 1 !important;
                        transform: translateY(0) !important;
                    }

                    .explore-container {
                        .title, svg {
                            opacity: 1 !important;
                            transform: translateX(0) !important;
                        }
                    }
                }
            }

            &.active {
                opacity: 1;
                pointer-events: auto;
                transition: all .45s .3s $ease;

                .image {
                    img {
                        opacity: 1;
                        transform: scale(1);
                        transition: all .45s .3s $ease;
                    }
                }

                .title {
                    opacity: 1;
                    transition: all .45s .3s $ease;
                }

                li {
                    opacity: 1 !important;
                    transform: translateY(0) !important;
                    transition: all .45s $ease;

                    @for $i from 1 through 5 {
                        &:nth-child(#{$i}) {
                            transition-delay: .3+ $i/10+s !important;
                        }
                    }
                }

                .explore-container {
                    cursor: pointer;
                    &:hover {
                        svg {
                            opacity: .6;
                            animation: goBack .45s ease-out;
                        }

                        .title {
                            opacity: .6;
                        }
                    }

                    .title, svg {
                        opacity: 1;
                        transform: translateX(0);
                        transition: all .45s .3s $ease;
                    }
                }
            }
        }

        .image-container {
            position: relative;

            .image-pins {
                position: absolute;
                width: 100%;
                height: 100%;
                left: 0;
                top: 0;

                .item {
                    position: absolute;
                    width: fit-content;
                    cursor: pointer;
                    transform: translate(-50%, -50%);
                    @for $i from 1 through 12 {
                        &:nth-child(#{$i}) {
                            z-index: #{$i};
                        }
                    }

                    &:hover {
                        .tooltip {
                            opacity: 1 !important;
                        }
                    }
                }

                .dot {
                    border-radius: 50%;
                    background: white;
                    width: 15px;
                    height: 15px;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    .point {
                        border-radius: 50%;
                        width: 8px;
                        height: 8px;
                        background: #940128;
                    }
                }

                .tooltip {
                    pointer-events: none;
                    position: absolute;
                    top: 0;
                    transform: translate(-50%, -105%);
                    left: 50%;
                    padding: 14px;
                    border-radius: 5px;
                    background: white;
                    opacity: 0;
                    transition: all .45s ease-out;
                    z-index: 12;

                    .text {
                        font-style: normal;
                        font-weight: 400;
                        font-size: 13px;
                        line-height: 16px;
                    }
                }
            }
        }


        .image:deep {
            width: 100%;
            @media (max-width: 1023px) {
                display: none;
            }

            img {
                width: 100%;
                height: 341px;
                object-fit: cover;
                opacity: 0;
                transform: scale(.8);
                transition: all .45s $ease;
            }
        }

        .content:deep {
            margin-top: 49px;
            margin-left: 18%;
            color: $primaryNavy;
            will-change: transform;
            @media (max-width: 1023px) {
                margin-top: 30px;
                margin-left: 0;
            }

            .title {
                font-weight: 400;
                font-size: 55px;
                line-height: 52px;
                text-transform: uppercase;
                opacity: 0;
                transition: all .45s $ease;
                @media (max-width: 1023px) {
                    font-size: 34px;
                }
            }

            .about-list {
                margin-top: 21px;

                li {
                    font-style: normal;
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 28px;
                    margin-left: 20px;
                    opacity: 0;
                    transform: translateY(20px);
                    transition: all .45s $ease;
                    display: flex;
                    @for $i from 1 through 5 {
                        &:nth-child(#{$i}) {
                            transition-delay: $i/10+s;
                        }
                    }
                    @media (max-width: 1023px) {
                        margin-left: 40px;
                    }

                    &::before {
                        content: "\2022";
                        color: $burgundy;
                        font-weight: bold;
                        display: inline-block;
                        width: 15px;
                        font-size: 40px;
                        margin-left: -1em;
                        margin-top: 3px;
                    }
                }
            }

            .explore-container {
                margin-top: 21px;
                display: flex;
                align-items: center;

                .title {
                    color: $burgundy;
                    font-weight: 400;
                    font-size: 16px;
                    margin-left: 15px;
                    letter-spacing: 0.2em;
                    text-transform: uppercase;
                    opacity: 0;
                    transition: all .45s $ease;
                }

                svg {
                    opacity: 0;
                    transform: translateX(-15px);
                    transition: all .45s $ease;
                }
            }
        }
    }

    @keyframes goBack {
        0% {
            transform: translateX(0);
        }
        50% {
            transform: translateX(5px);
        }
        100% {
            transform: translateX(0);
        }
    }

}
</style>
<style lang="scss">
body {
    &.is-dark {
        .project-view {
            .content {
                color: white !important;

                span, p, h2, h1 {
                    color: white !important;
                }
            }
        }
    }
}

</style>
